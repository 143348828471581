import React from "react"
import { Link } from "gatsby"
import headerStyles from "../styles/components/headerrow.module.scss"

export default function Header(props) {
  return (
    <header
      className={`${headerStyles.header} ${props.page === 'info' &&
        headerStyles.info_page}`}
    >
      <nav
        className={headerStyles.header__nav}
        role="navigation"
        aria-label="main navigation"
      >
        <Link to="/">
          <h1>{props.title}</h1>
        </Link>
        <div className={headerStyles.spacer} />
        <div>
          <h3>
            <Link
              to={"/articles"}
              activeClassName={headerStyles.navItemActive}
            >
              Articles
            </Link>
          </h3>
        </div>
        <div>
          <h3>
            <Link
              to={
                props.page === 'info'
                  ? "/"
                  : "/info"
              }
              activeClassName={headerStyles.navItemActive}
            >
              {props.page === 'info'
                ? "Close"
                : "About"}
            </Link>
          </h3>
        </div>
      </nav>
    </header>
  )
}